var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ElButton',{class:[
    _vm.dropdown || _vm.newDropdown && 'el-button--dropdown',
    _vm.kind && `el-button--${_vm.kind}`,
    _vm.opacity && 'el-button--unimpressed',
    _vm.size && `el-button--${_vm.size || ''}`,
    _vm.type && `el-button--${_vm.type || ''}`,
    _vm.fullWidth && !_vm.icon && 'el-button--full-width',
    _vm.fullWidth && _vm.icon && 'el-button--full-width-with-icon',
    {
      'is-circle': _vm.circle,
      'is-disabled': _vm.disabled,
      'is-loading': _vm.loading,
      'is-no-border': _vm.noBorder,
      'is-like-anchor': _vm.likeAnchor,
      'is-plain': _vm.plain,
      'is-round': _vm.round,
      'el-button--long-text': _vm.withLongText,
      'el-button-warning': _vm.warningNew,
      'el-button--new-design': _vm.newDesign,
      'el-button--navbar': _vm.isNavbarButton,
      'el-button--state-badge': _vm.hasStateBadge,

    },
  ],attrs:{"disabled":_vm.disabled || _vm.loading,"autofocus":_vm.autofocus,"type":_vm.nativeType},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._v(" "),(_vm.icon && !_vm.loading)?_c('i',{class:_vm.icon}):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.newDropdown && !_vm.loading)?_c('i',{staticClass:"icon icon--right-side icon-chevron-down-small"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }