var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white-container--with-padding",class:[
    {
      'white-container--with-header': _vm.title,
      'white-container--with-small-padding': _vm.title && _vm.smallPadding,
      'white-container--with-header-no-separator': _vm.hideHeaderSeparator,
      [`white-container--${_vm.type}`]: _vm.type,
      [`white-container--${_vm.headerSize}`]: _vm.headerSize,
    },
  ]},[(_vm.hasHeaderSlot)?_c('div',[_vm._t("header")],2):_vm._e(),_vm._v(" "),((_vm.title && !_vm.hasHeaderSlot))?_c('div',{class:['header', _vm.headerClass]},[_c('div',{class:[
        'header-col',
        `white-container-header--${_vm.titleSize}`
      ]},[_c('div',{staticClass:"header-with-image"},[(_vm.titleIcon)?_c('img',{attrs:{"src":_vm.titleIcon}}):_vm._e(),_vm._v(" "),_c('h2',[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")])]),_vm._v(" "),(!!this.$slots['header-extra-content'])?_c('div',{staticClass:"header-extra-content"},[_vm._t("header-extra-content")],2):_vm._e()]),_vm._v(" "),(!!this.$slots['header-center'])?_c('div',{staticClass:"header-col-center"},[_vm._t("header-center")],2):_vm._e(),_vm._v(" "),(!!this.$slots['header-right'])?_c('div',{staticClass:"header-col-right"},[_vm._t("header-right")],2):_vm._e()]):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }